import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
const PostLink = function({ post }) {
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;
  return (
    <div class="">
      <Link to={post.frontmatter.path}>
        <Img fluid={featuredImgFluid} />
      </Link>
      <Link class="list-title" to={post.frontmatter.path}>
        {post.frontmatter.title}
      </Link>
      <p>{post.excerpt}</p>
      <p class="readtime">
        {post.frontmatter.date} . {post.fields.readingTime.text}
      </p>
    </div>
  );
};

export default PostLink;
