import React from "react";
import BodyClassName from "react-body-classname";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/juster.css";
import "../css/animation.css";
import { graphql } from "gatsby";
import PostLink from "../components/post-link";
import { Helmet } from "react-helmet"

const Blogs = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />);

  return (
    <>
      <BodyClassName className="blog" />
      <Helmet>
      <title>JustEZ - Do More Inspections!</title>          
      <meta name="description" content="The last mile app for claim adjusters. Schedule, Navigate, Record, and Get scoping reports. All features that adjusters require during the Field Inspection. Sign up today for free! " />
      <meta name="keywords" content="claims adjusters,adjusters,insurance adjusters,inspections,sync inspections,scoping reports,inspection reports,sync claims,claim management,claim management system,field inspection"/>
    </Helmet>
      <Header></Header>
      <div class="blog-header">
        <h2>
          JustEZ<span>Do More Inspections!</span>
        </h2>
      </div>
      <div class="container-main">
        <div class="container blog-list">{Posts}</div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Blogs;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            path
            title
            caption
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;
